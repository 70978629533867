



















































































import { Component, Ref, Vue } from "vue-property-decorator"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule"
import { getModule } from "vuex-module-decorators"
import StringTool from "@/services/tool/StringTool"
import LangModule from "@/store/LangModule"
import RecoverPasswordDialog from "@/components/dialogs/RecoverPasswordDialog.vue"
import UserIcon from "@/assets/custom_icons/user.svg"

@Component({components: { RecoverPasswordDialog, UserIcon }})

export default class LoginView extends Vue {

	@Ref() readonly requestRecoveryForm!: HTMLFormElement
  @Ref() readonly form!: HTMLFormElement
  lang: any = getModule(LangModule).lang
  loading: boolean = false
  email: string = ""
  password: string = ""
  hidePassword: boolean = true
  sessionModule: SessionModule = getModule(SessionModule)
  dialog: boolean = false
	recoveryDialog: boolean = false

	emailRules = [
    (v: string) => v.length > 0 ? true : this.lang.requiredInput,
    (v: string) => StringTool.validateEmail(v) ? true : this.lang.invalidEmail
  ]

  passwordRules = [
    (v: string) => v.length > 0 ? true : this.lang.requiredPassword
  ]

  login() {
    LoginService.postLogin(this, this.email, this.password, getModule(SnackbarModule), this.sessionModule)
  }

	onSuccessRequestedRecovery() {
		this.dialog = false
		this.recoveryDialog = true
	}
}

